import React, {FC, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import AuthModal from "@app/views/authPage/components/authModal";
import Logo from '../../icons/icon-ampm.svg';
import LogoDamixa from '../../icons/icon-damixa.svg';
import LogoDorff from '../../icons/icon-dorff.svg';
import BackgroundImageDesktop from "@app/images/main-page-desktop.png";
import BackgroundImageTablet from "@app/images/main-page-tablet.png";
import * as S from './styled';
import {useAppDispatch, useAppSelector} from "@app/store";
import {fetchUser, setConfirmStatus, signIn} from "@app/store/core/slice";
import {selectConfirmStatus, selectSignIn, selectUser} from "@app/store/core/selectors";
import {RemoteDataStatus} from "../../../libs/remote";
import {useRouter} from "next/router";
import {getCookie} from "@app/utils/core-functions";
import {openErrorNotification} from "@app/components/ui/notification";

const phoneRegExp= /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;

type FormInputsType = {
    phone: string
}

const schema = yup.object({
    phone: yup.string().matches(phoneRegExp, 'Введите номер телефона').required(),
}).required();

const AuthPage: FC = () => {
    const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const dispatch = useAppDispatch();
    const confirmStatus = useAppSelector(selectConfirmStatus);
    const confirm = useAppSelector(selectSignIn);
    const user = useAppSelector(selectUser);
    const router = useRouter();
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<FormInputsType>({resolver: yupResolver(schema)});

    const submitHandler = (data: FormInputsType) => {
        dispatch(signIn({phone: Number(data.phone.slice(1).replace(/\s/g, ""))}));
        setPhoneNumber(data.phone);
    };

    useEffect(() => {
        if (confirm.status === RemoteDataStatus.Success) {
            setIsAuthModalOpen(true);
        }
        if (confirm.status === RemoteDataStatus.Failure) {
            openErrorNotification();
        }
    }, [confirm]);

    useEffect(() => {
        if (document) {
            if (getCookie("access_token")) {
                dispatch(fetchUser());
            }
        }
    }, []);

    useEffect(() => {
        if (user.status === RemoteDataStatus.Success) {
            if (user.data.is_user_verified) {
                router.push("/myGoods");
            } else {
                router.push("/registration");
            }
        }
    }, [user])

    useEffect(() => {
        if (confirmStatus === RemoteDataStatus.Success && confirm.status === RemoteDataStatus.Success) {
            if (confirm.data.is_user_verified) {
                dispatch(setConfirmStatus(RemoteDataStatus.Pending))
                router.push("/myGoods");
            } else {
                dispatch(setConfirmStatus(RemoteDataStatus.Pending))
                router.push("/registration");
            }
        }
    }, [confirmStatus])

    return (
        <>
        <S.Container>
            <S.BackgroundImageDesktop src={BackgroundImageDesktop} alt={"Фоновое изображение"}/>
            <S.BackgroundImageTablet src={BackgroundImageTablet} alt={"Фоновое изображение"}/>
            <S.BackgroundImageMobile src={BackgroundImageTablet} alt={"Фоновое изображение"}/>
            <S.SignWrap onSubmit={handleSubmit(submitHandler)}>
                <S.SignTitle>
                    Войти в личный кабинет
                </S.SignTitle>
                <S.BrandsWrap>
                    <Logo />
                    <LogoDamixa />
                    <LogoDorff />
                </S.BrandsWrap>
                <S.InputLabel>Номер телефона:</S.InputLabel>
                <S.PhoneInput
                    type="phone"
                    id="phone"
                    mask={"+7 999 999 99 99"}
                    placeholder="Номер телефона: +7"
                    {...register("phone")}
                />
                {<S.FormErrors>{errors.phone?.message}</S.FormErrors>}
                <S.GetSmsBtn
                    loading={confirm.status == RemoteDataStatus.Pending}
                    onClick={handleSubmit(submitHandler)}
                    type="submit"
                >
                    Получить SMS для входа
                </S.GetSmsBtn>
            </S.SignWrap>
        </S.Container>
            {isAuthModalOpen && <AuthModal onClose={() => setIsAuthModalOpen(false)} phoneNumber={phoneNumber} titleText={"Введите код"}/>}
        </>
    );
}

export default AuthPage;

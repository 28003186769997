import React, { FC, useEffect } from "react";
import IconClose from "../../../icons/icon-close.svg";
import * as S from './styled';

export const isEscEvent = (evt: KeyboardEvent) => evt.key === "Escape" || evt.key === "Esc";

type Props = {
    onClose: () => void;
    children: React.ReactNode;
    isBigHeightModal?: boolean;
};

const Modal: FC<Props> = ({ onClose, children, isBigHeightModal }) => {

    useEffect(() => {
        const handleEscKeyDown = (evt: KeyboardEvent) => {
            if (!isEscEvent(evt)) {
                return;
            }
            onClose();
        };
        document.addEventListener("keydown", handleEscKeyDown);

        return () => {
            document.removeEventListener("keydown", handleEscKeyDown);
        };
    }, [onClose]);

    useEffect(() => {
        document.body.style.overflow = "hidden";

        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

        return (
            <S.ModalOverlay onClick={onClose}>
                <S.ModalWrap isBigHeightModal={isBigHeightModal} onClick={(evt) => evt.stopPropagation()}>
                    <S.ButtonClose onClick={onClose}>
                        <S.IconCloseWrap>
                            <IconClose />
                        </S.IconCloseWrap>
                    </S.ButtonClose>
                    {children}
                </S.ModalWrap>
            </S.ModalOverlay>
        );
}

export default Modal;

import styled, { css } from "styled-components";
import InputMask from "react-input-mask";
import {Button} from "antd";
import ReactCodeInput from "react-code-input";

export const Wrap = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    flex-direction: column;
    gap: 34px;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      max-width: 280px;
      width: 100%;
      gap: 24px;
    }
  `}
`;

export const Title = styled.h3`
  ${({ theme: { breakpoints } }) => css`
    font-size: 24px;
    font-weight: 400;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      font-size: 18px;
    }
  `}
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const Content = styled.p`
  font-size: 14px;
  line-height: 1.5;
  max-width: 300px;
`;

export const ChangePhoneButton = styled.button`
  border: none;
  background-color: transparent;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

export const InputLabel = styled.label`
  font-size: 13px;
  text-align: center;
`;

export const InputTitle = styled.p`
  font-size: 13px;
  text-align: center;
`;

export const CodeInput = styled(InputMask)`
  ${({ theme: { colors, breakpoints } }) => css`
    border-radius: 10.5px;
    border: 1px solid ${colors.lineGray};
    display: flex;
    align-items: center;
    height: 44px;
    width: 100%;
    font-size: 13px;
    padding: 0 16px;
    align-self: center;
    margin: 6px;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      margin-top: 6px;
    }
  `}
`;

export const GetNewCodeTimer = styled.p`
  ${({ theme: { colors } }) => css`
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    max-width: 160px;
    align-self: center;
    color: ${colors.darkGray};
  `}
`;

export const GetNewCode = styled(Button)`
  ${({ theme: { colors } }) => css`
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    height: 44px;
    border-radius: 10.5px;
    background-color: ${colors.black};
    color: ${colors.white};
    text-transform: uppercase;
    font-size: 13px;
    padding: 0 40px;
    width: 100%;
    cursor: pointer;

    &:hover, &:focus {
      background-color: ${colors.black} !important;
      color: ${colors.white} !important;
      border: none !important;
    }
  `}
`;

export const SubmitCode = styled(GetNewCode)`
  margin-top: 12px;
`;

export const FormErrors = styled.p`
  ${({ theme: { colors } }) => css`
    font-size: 11px;
    color: ${colors.red};
    text-align: center;
  `}
`;

export const ConfirmInput = styled(ReactCodeInput)`
  ${({ theme: { unit, colors } }) => css`
    width: max-content;
    color: ${colors.white};
    display: flex !important;
    gap: 8px;
    flex-wrap: nowrap;
    margin: 8px auto 0;
  `}
`;

import React, {FC, useEffect, useState} from "react";
import Modal from "@app/components/ui/modal";
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import * as S from './styled';
import {useAppDispatch, useAppSelector} from "@app/store";
import {confirmUser, resetConfirmation} from "@app/store/core/slice";
import {selectConfirmStatus, selectResetConfirmStatus, selectSignIn} from "@app/store/core/selectors";
import {RemoteDataStatus} from "../../../../../libs/remote";
import {SubmitCode} from "./styled";

type FormInputsType = {
    code: string
}

const schema = yup.object({
    phone: yup.string().required().max(4),
}).required();

type Props = {
    onClose: () => void;
    phoneNumber: string;
    titleText: string;
};

const AuthModal: FC<Props> = ({ onClose, phoneNumber, titleText }) => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [isNewCodeAvailable, setIsNewCodeAvailable] = useState(false);
    const [timer, setTimer] = useState(59);
    const dispatch = useAppDispatch();
    const confirm = useAppSelector(selectSignIn);
    const confirmStatus = useAppSelector(selectConfirmStatus);
    const resetConfirmStatus = useAppSelector(selectResetConfirmStatus);
    const [code, setCode] = useState("");
    const [error, setError] = useState(false);

    const {
        handleSubmit,
        register,
        watch
    } = useForm<FormInputsType>({resolver: yupResolver(schema)});
/*    const code = watch("code");*/

    useEffect(() => {
        if (confirmStatus === RemoteDataStatus.Failure) {
            setErrorMessage("Неверный код. Попробуйте ещё раз")
        }
    }, [confirmStatus]);

    useEffect(() => {
        if (!!timer) {
            const interval = setInterval(() => setTimer(timer - 1), 1000);
            setIsNewCodeAvailable(false);

            return () => clearInterval(interval);
        } else {
            setIsNewCodeAvailable(true);
        }
    }, [timer]);

    const onSubmit = (data: FormInputsType) => {
        if (confirm.status === RemoteDataStatus.Success) {
            dispatch(confirmUser({confirmation_id: confirm.data.confirmation_id, code: code}))
        }
    };

    const newCodeSubmit =  () => {
        if (confirm.status === RemoteDataStatus.Success) {
            dispatch(resetConfirmation({confirmation_id: confirm.data.confirmation_id}));
            setTimer(59);
        }
    }

    const props = {
        inputStyle: {
            MozAppearance: "textfield",
            borderRadius: "10.5px",
            fontSize: "16px",
            height: "44px",
            color: "black",
            width: "44px",
            textAlign: "center",
            border: "1px solid #E4E7EA",
        },
    }

    return (
            <Modal onClose={onClose}>
                <S.Wrap>
                    <S.Title>
                        {titleText}
                    </S.Title>
                    <S.ContentWrap>
                    <S.Content>
                        {`Мы отправили код подтверждения на номер ${phoneNumber}`}
                    </S.Content>
                        <S.ChangePhoneButton onClick={onClose}>Изменить</S.ChangePhoneButton>
                    </S.ContentWrap>
                    <S.Form onSubmit={handleSubmit(onSubmit)}>
                        <S.InputLabel htmlFor="code">
                            <S.InputTitle>Код подтверждения:</S.InputTitle>
{/*                        <S.CodeInput
                            type="string"
                            mask={"9999"}
                            id="code"
                            {...register("code")}
                        />*/}
                            <S.ConfirmInput
                              type={"number"}
                              name={"code"}
                              inputMode={"numeric"}
                              value={code}
                              onChange={(e: string) => {
                                  setCode(e);
                                  setError(false);
                              }}
                              {...props}
                            />
                        </S.InputLabel>
                        {<S.FormErrors>{errorMessage}</S.FormErrors>}
                        {!isNewCodeAvailable && <S.GetNewCodeTimer>{`Получить новый код можно через 00:${timer}`}</S.GetNewCodeTimer>}
                        {!!+code && (
                            <S.SubmitCode
                                type="submit"
                                onClick={onSubmit}
                                loading={confirmStatus === RemoteDataStatus.Pending}
                            >
                                Отправить
                            </S.SubmitCode>
                        )}
                        {isNewCodeAvailable && (
                          <S.GetNewCode
                            onClick={newCodeSubmit}
                            loading={resetConfirmStatus === RemoteDataStatus.Pending}
                          >
                              Получить новый код
                          </S.GetNewCode>
                        )}
                    </S.Form>
                </S.Wrap>
            </Modal>
        );
}

export default AuthModal;

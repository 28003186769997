import {serverSideTranslations} from "next-i18next/serverSideTranslations";
import AuthPage from "@app/views/authPage";
import Head from "next/head";
import React from "react";

const Index = () => {

    return (
        <>
            <Head>
                <title>Главная страница </title>
            </Head>
            <AuthPage/>
        </>
    )
}

// export async function getStaticProps({ locale }) {
//     return {
//         props: {
//             ...(await serverSideTranslations(locale, ["common"])),
//         },
//     };
// }

export default Index;

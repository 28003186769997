import styled, { css } from "styled-components";

export const ModalOverlay = styled.div`
  ${({ theme: { colors } }) => css`
    position: fixed;
    overflow: auto;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.deepBlackOpacity};
    display: flex;
    justify-content: center;
    z-index: 20;
  `}
`;

export const ModalWrap = styled.div<{isBigHeightModal?: boolean}>`
  ${({ theme: { breakpoints, colors }, isBigHeightModal }) => css`
    position: relative;
    margin: auto;
    background: ${colors.white};
    border-radius: 10px;
    max-width: 1012px;
    max-height: ${!isBigHeightModal && 'min(calc(100% - 80px), 740px)'};
    padding: 32px;
    overflow-y: ${isBigHeightModal ? "hidden" : "initial"};
    overflow-x: auto;
    height: ${isBigHeightModal && "min-content"};

    @media (max-height: 780px) {
      height: ${isBigHeightModal && "80%"};
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      padding: 20px;
      max-width: calc(100% - 32px);
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: ${colors.bgGray};
      width: 1160px;
      margin-left: 64px;
      margin-right: 64px;
    }

    &::-webkit-scrollbar {
      height: 8px;
      background-color: white;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: ${colors.black};
    }
  `}
`;

export const IconCloseWrap = styled.div`
  position: absolute;
  margin: 0;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    fill: red;
  }
`;

export const ButtonClose = styled.button`
  ${({ theme: { breakpoints } }) => css`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    top: 23px;
    right: 24px;
    border: none;
    background-color: transparent;

    @media (max-width: ${breakpoints.maxTableWidth}) {
      top: 23px;
      right: 23px;
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      width: 18px;
      height: 18px;
      top: 15px;
      right: 15px;
    }
  `}
`;
